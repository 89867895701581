import DefaultPayment from "./default_payment";
import Transactions from "./transactions";

const paymentRoutes = [
  {
    path: "/core/payments/settings",
    element: <DefaultPayment />,
  },
  {
    path: "/core/payments/transactions",
    element: <Transactions />,
  },
];

export default paymentRoutes;
