
import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// import { Accordion, AccordionTab } from "primereact/accordion";

import { getItem, clearStorage } from '../../../utils/localStorage';
import { makeRequest } from '../../../utils/makeRequest'
import { checkTokenExpirationURL } from '../../../utils/urls';
function Nav() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate()
    const [userName, setFullname] = useState("")
    const [userRole, setRole] = useState("")

    const HandleLogOut = async () => {
        await clearStorage()
        navigate('/')
    }
    const getCurrentUser = async () => {
        try {
            const currentUser = await getItem('COREUSER')
            if (currentUser) {
                const fullName = currentUser.user.fullName
                const role = currentUser.user.type
                setFullname(fullName)
                setRole(role)
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }
    const confirmToken = async () => {
        try {
            const userDetails = await getItem('COREUSER')
            const token = userDetails.authToken

            const response = await makeRequest(checkTokenExpirationURL, 'POST', {
                token: token
            })

            if (response.success === false) {
                await clearStorage()
                navigate('/')
            }

        }
        catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        getCurrentUser()
        confirmToken()

    }, [])
    return (
        <nav className="pc-sidebar">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <Link to={"/core/dashboard"} className="b-brand text-primary">
                        <img
                            src="/assets/images/PayServeLogoFinal.png"
                            className="img-fluid logo-lg"
                            alt="logo"
                            style={{ width: 150 }}
                        />
                        <span className="badge bg-light-success rounded-pill ms-2 theme-version">
                            v1.0
                        </span>
                    </Link>
                </div>
                <div className="navbar-content" style={{ overflowY: 'scroll' }}>
                    <div className="card pc-user-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        src="/assets/images/user/avatar-1.jpg"
                                        alt="user-image"
                                        className="user-avtar wid-45 rounded-circle"
                                    />
                                </div>
                                <div className="flex-grow-1 ms-3 me-2">
                                    <h6 className="mb-0">{userName}</h6>
                                    <small>{userRole}</small>
                                </div>
                                <Link
                                    className="btn btn-icon btn-link-secondary avtar"
                                    data-bs-toggle="collapse"
                                    to="#pc_sidebar_userlink"
                                >
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-sort-outline" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                                <div className="pt-3">

                                    <Link to={"/core/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link>

                                    <Link to={"#!"} onClick={() => { HandleLogOut() }}>
                                        <i className="ti ti-power" />
                                        <span>Logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="pc-navbar mb-5">
                        <li className={`pc-item ${currentPath === '/core/dashboard' ? 'active' : ''}`}>
                            <Link to={'/core/dashboard'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-home" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Dashboard</span>
                            </Link>
                        </li>


                        <li className="pc-item pc-caption">
                            <label>Business Partner Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/companies' || currentPath === '/core/add_company' ? 'active' : ''}`}>
                            <Link to={'/core/companies'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-data" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Business Partners</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/facilities' ? 'active' : ''}`}>
                            <Link to={'/core/facilities'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-row-vertical" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Facilities</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Water Meter Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meter_management/configs'?`active`:``}`}>
                            <Link to={'/core/water_meter_management/configs'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-setting-2" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Configs</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meter_management/concentrators' ? 'active' : ''}`}>
                            <Link to={'/core/water_meter_management/concentrators'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Concentrators</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meter_management/meters'  ? 'active' : ''}`}>
                            <Link to={'/core/water_meter_management/meters'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-cpu-charge" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Meters</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meter_management/assign'?`active`:``}`}>
                            <Link to={'/core/water_meter_management/assign'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-data" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Assign</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/water_meter_management/deliveries' ? 'active' : ''}`}>
                            <Link to={'/core/water_meter_management/deliveries'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-folder-open" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Post-Delivery Report</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>More</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/messages' ? 'active' : ''}`}>
                            <Link to={'/core/messages'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-message-2" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Messages</span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Payments</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/payments/setting' ? 'active' : ''}`}>
                            <Link to={'/core/payments/settings'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-message-2" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Settings</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/core/payments/transactions' ? 'active' : ''}`}>
                            <Link to={'/core/payments/transactions'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-message-2" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Transactions</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}
export default Nav