import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { Link } from "react-router-dom";
import Layout from "../component/layout";

const DefaultPayment = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({
    shortCode: "",
    passkey: "",
    authorizationKey: "",
    module: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const MODULE_OPTIONS = ["All", "Water", "Electricity", "Levy", "Lease"];

  const fetchPaymentDetails = async () => {
    try {
      const response = await makeRequest2(
        "/api/core/payment_details/get_default_payment_details",
        "GET"
      );
      if (response.success) {
        setPaymentDetails(response.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const truncateText = (text, length = 15) => {
    return text?.length > length ? `${text.substring(0, length)}...` : text;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (isEditing && selectedPayment) {
        response = await makeRequest2(
          `/api/core/payment_details/update_default_payment_details/${selectedPayment._id}`,
          "PUT",
          formData
        );
      } else {
        response = await makeRequest2(
          "/api/core/payment_details/add_default_payment_details",
          "POST",
          formData
        );
      }

      if (response.success) {
        toastify(
          isEditing
            ? "Payment details updated successfully"
            : "Payment details added successfully",
          "success"
        );
        setShowDialog(false);
        fetchPaymentDetails();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = (payment) => {
    setSelectedPayment(payment);
    setFormData({
      shortCode: payment.shortCode,
      passkey: payment.passkey,
      authorizationKey: payment.authorizationKey,
      module: payment.module,
    });
    setIsEditing(true);
    setShowDialog(true);
  };

  const handleAdd = () => {
    setSelectedPayment(null);
    setIsEditing(false);
    setFormData({
      shortCode: "",
      passkey: "",
      authorizationKey: "",
      module: "",
    });
    setShowDialog(true);
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Payment Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Default Payment</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Default Payment Details</h5>
              {paymentDetails.length === 0 && (
                <button className="btn btn-primary" onClick={handleAdd}>
                  <i className="ti ti-plus me-2"></i>Add Payment Details
                </button>
              )}
            </div>

            <div className="card-body">
              <div className="row">
                {paymentDetails.map((payment) => (
                  <div key={payment._id} className="col-lg-4 col-xxl-3">
                    <div className="card h-100">
                      <div className="card-body position-relative">
                        <div className="text-center">
                          <div className="chat-avtar d-inline-flex mx-auto mb-3">
                            <i
                              className="ti ti-credit-card"
                              style={{ fontSize: 50 }}
                            ></i>
                          </div>
                          <hr className="my-2 border border-secondary-subtle" />

                          <div className="payment-details">
                            <div className="text-start mb-2">
                              <small className="text-muted d-block">
                                Short Code:
                              </small>
                              <span className="text-break">
                                {payment.shortCode}
                              </span>
                            </div>
                            <div className="text-start mb-2">
                              <small className="text-muted d-block">
                                Pass Key:
                              </small>
                              <span
                                className="text-break"
                                title={payment.passkey}
                              >
                                {truncateText(payment.passkey)}
                              </span>
                            </div>
                            <div className="text-start mb-2">
                              <small className="text-muted d-block">
                                Authorization Key:
                              </small>
                              <span
                                className="text-break"
                                title={payment.authorizationKey}
                              >
                                {truncateText(payment.authorizationKey)}
                              </span>
                            </div>
                            <div className="text-start mb-3">
                              <small className="text-muted d-block">
                                Module:
                              </small>
                              <span className="text-break">
                                {payment.module}
                              </span>
                            </div>
                          </div>

                          <button
                            className="btn btn-primary btn-sm w-100"
                            onClick={() => handleEdit(payment)}
                          >
                            <i className="ti ti-edit me-1"></i>Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={isEditing ? "Edit Payment Details" : "Add Payment Details"}
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        style={{ width: "50vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">
                  Short Code<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="shortCode"
                  value={formData.shortCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Pass Key<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="passkey"
                  value={formData.passkey}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">
                  Authorization Key<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="authorizationKey"
                  value={formData.authorizationKey}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Module<span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  name="module"
                  value={formData.module}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select a module</option>
                  {MODULE_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="text-end mt-4">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={() => setShowDialog(false)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {isEditing ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Dialog>
    </Layout>
  );
};

export default DefaultPayment;
