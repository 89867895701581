import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../component/layout";
import { areaMeasurements } from "../../../utils/data";
import { makeRequest2 } from "../../../utils/makeRequest";
import {
  addUnitAsset,
  deleteUnitAsset,
  getUnitAsset,
  getUnitUrl,
  updateUnit,
  DisableUnit,
  EnableUnit,
  releaseUnitURL,
  releaseUnitFieldURL
} from "../../../utils/urls";
import { toastify } from "../../../utils/toast";
import { unitTypes } from "../../../utils/data";
import ConfirmDeleteDialog from "../component/confirmDialog";

// Sample data

const ViewUnit = () => {
  const navigate = useNavigate();
  const { unitId, facilityId } = useParams();
  const [unit, setUnit] = useState({});
  const [utilityHistory, setUtilityHistory] = useState([]);
  const [assets, setAssets] = useState([]);
  const [units, setUnits] = useState([]);
  const [assetName, setAssetName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [unitAssets, setUnitAssets] = useState([]);
  const [newAssetDialog, setNewAssetDialog] = useState(false);
  const [editUtilityDialog, setEditUtilityDialog] = useState(false);
  const [editUnitDialog, setEditUnitDialog] = useState(false);
  const [utilityName, setUtilityName] = useState("");
  const [selectedUtility, setSelectedUtility] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [unitType, setUnitType] = useState("");
  const [floorUnit, setFloorUnit] = useState("");
  const [currentClickedUnit, setCurrentClickedUnit] = useState({});
  const [lrNumber, setLrNumber] = useState("");
  const [division, setDivision] = useState("");
  const [facility, setFacility] = useState({});
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [tenant, setTenant] = useState({});
  const [homeOwner, setHomeOwner] = useState({});
  const [resident, setResident] = useState({});

  const defaultMeasurement = areaMeasurements.find(
    (measurement) => measurement.default
  ) || { symbol: "" };

  const HandleDeleteAsset = async (id) => {
    try {
      const response = await makeRequest2(
        deleteUnitAsset + "/" + id + "/" + facilityId,
        "DELETE"
      );
      if (response.success) {
        toastify(response.data, "success");
        getUnitAssets();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const getUnit = async () => {
    try {
      const response = await makeRequest2(
        getUnitUrl + "/" + unitId + "/" + facilityId,
        "GET"
      );

      if (response.success === true) {
        const unit = response.data;
        setUnit(unit)
        setUnitName(unit.name);
        setUnitType(unit.unitType);
        setFloorUnit(unit.floorUnitNo);
        setLrNumber(unit.landRateNumber);
        setDivision(unit.division);

        setHomeOwner(unit.homeOwner || null);
        setTenant(unit.tenant || null);
        setResident(unit.resident || null);

      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const getUnitAssets = async () => {
    try {
      const response = await makeRequest2(
        getUnitAsset + "/" + unitId + "/" + facilityId,
        "GET"
      );
      if (response.success === true) {
        const unitAssets = response.data;
        setUnitAssets(unitAssets);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err.message);
      toastify(err.message, "error");
    }
  };

  const HandleAssetSubmit = async () => {
    try {
      const name = assetName;

      if (name === "") {
        throw new Error("Asset name is required.");
      } else {
        const response = await makeRequest2(
          addUnitAsset + "/" + unitId + "/" + facilityId,
          "POST",
          {
            name,
          }
        );
        if (response.success) {
          toastify("Asset added successfully", "success");
          setAssetName("");
          setNewAssetDialog(false);
          getUnitAssets();
        } else {
          throw new Error(response.error);
        }
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, "error");
    }
  };

  const HandleUnitUpdate = async () => {
    try {
      const updatedUnit = {
        unitName,
        unitType,
        floorUnit,
        lrNumber,
      };

      const response = await makeRequest2(
        `${updateUnit}/${unitId}/${facilityId}`,
        "POST",
        updatedUnit
      );

      if (response.success) {
        toastify(response.data, "success");
        setUnitName("");
        setUnitType("");
        setFloorUnit("");
        setLrNumber("");
        getUnit();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, "error");
    }
  };

  const handleDisableUnit = async () => {
    try {
      const response = await makeRequest2(
        DisableUnit + "/" + unitId + "/" + facilityId,
        "GET",
        {}
      );
      if (response.success) {
        getUnit();
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleEnableUnit = async () => {
    try {
      const response = await makeRequest2(
        EnableUnit + "/" + unitId + "/" + facilityId,
        "GET",
        {}
      );
      if (response.success) {
        getUnit();
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const deleteAssetTemplate = (rowData) => {
    return (
      <Link
        to={"#"}
        className="avtar avtar-xs btn-link-secondary btn-pc-default"
        onClick={() => {
          setSelectedAssetId(rowData._id);
          setDialogVisible(true);
        }}
      >
        <i className="ti ti-trash f-18" style={{ color: "red" }}></i>
      </Link>
    );
  };

  const handleConfirmDelete = async () => {
    setDialogVisible(false);
    await HandleDeleteAsset(selectedAssetId);
  };

  const releaseEntireUnit = async () => {
    try {
      const updatedFields = {
        homeOwnerId: "",
        residentId: "",
        tenantId: "",
      }
      const response = await makeRequest2(releaseUnitURL + '/' + unitId + '/' + facilityId, "POST", updatedFields);
      console.log("response", response);

      if (response.success) {
        toastify("Unit released successfully.", "success");
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.error("Error updating unit:", err);
      toastify(err.message, "error");
    }
  };

  const releaseUnitField = async (field) => {
    try {
      const response = await makeRequest2(releaseUnitFieldURL + '/' + unitId + '/' + facilityId, "POST", {
        updates: { [field]: "" },
      });
      console.log("response", response);

      if (response.success) {
        toastify(`Successfully removed ${field} from the unit`, "success");
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.error("Error updating unit:", err);
      toastify(err.message, "error");
    }
  };

  useEffect(() => {
    getUnit();
    getUnitAssets();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Unit Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>View Unit</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Link to={"#"} onClick={() => navigate(-1)}>
                    <span>
                      <i className="ti ti-arrow-narrow-left"></i> Back
                    </span>
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-body py-0">
                  <ul
                    className="nav nav-tabs profile-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="profile-tab-1"
                        data-bs-toggle="tab"
                        href="#profile-1"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="ti ti-building-bank me-2"></i>Info
                      </a>
                    </li>
                    {/* <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                <i className="ti ti-building-warehouse me-2"></i>Utility History
                                            </a>
                                        </li> */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab-3"
                        data-bs-toggle="tab"
                        href="#profile-3"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="ti ti-olympics me-2"></i>Assets
                      </a>
                    </li>
                    {/* <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                <i className="ti ti-file-text me-2"></i>Documents
                                            </a>
                                        </li> */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab-5"
                        data-bs-toggle="tab"
                        href="#profile-5"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="ti ti-settings me-2"></i>Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane show active"
                  id="profile-1"
                  role="tabpanel"
                  aria-labelledby="profile-tab-1"
                >
                  <div className="row">
                    <div className="col-lg-4 col-xxl-3">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="text-center mt-3">
                            <div className="chat-avtar d-inline-flex mx-auto">
                              <i
                                className="ti ti-building-bank me-2"
                                style={{ fontSize: 100 }}
                              ></i>
                            </div>
                            <h5 className="mb-0"></h5>
                            <h2 className="text-muted mt-2">{unitName}</h2>
                            <hr className="my-3 border border-secondary-subtle" />

                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              <p className="mb-0">
                                <strong>Unit Type: </strong>
                                {unitType}
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              <p className="mb-0">
                                <strong>Division: </strong>
                                {division}
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              <p className="mb-0">
                                <strong>Floors Unit No: </strong>0{" "}
                                {defaultMeasurement.symbol}
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              <p className="mb-0">
                                <strong>lettableFloorArea: </strong>123456
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              <p className="mb-0">
                                <strong>landRateNumber: </strong>1500{" "}
                                {defaultMeasurement.symbol}
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                              {unit.isEnabled ? (
                                <button
                                  className="btn btn-danger btn-block mx-auto"
                                  onClick={handleDisableUnit}
                                >
                                  Disable Unit
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary btn-block mx-auto"
                                  onClick={handleEnableUnit}
                                >
                                  Enable Unit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-2"
                  role="tabpanel"
                  aria-labelledby="profile-tab-2"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="dt-responsive table-responsive">
                        <DataTable
                          value={utilityHistory}
                          emptyMessage="No utility history available."
                          sortMode="multiple"
                          paginator
                          rows={10}
                          stripedRows
                          tableStyle={{ minWidth: "50rem" }}
                        >
                          <Column
                            field="serialNumber"
                            header="Serial Number"
                            sortable
                          ></Column>
                          <Column field="type" header="Type" sortable></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-3"
                  role="tabpanel"
                  aria-labelledby="profile-tab-3"
                >
                  <div className="card">
                    <div className="card-header">
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-primary"
                          onClick={() => setNewAssetDialog(true)}
                        >
                          Add New Asset
                        </button>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <DataTable
                            value={unitAssets}
                            emptyMessage="No assets found."
                            sortMode="multiple"
                            paginator
                            rows={10}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                          >
                            <Column
                              field="name"
                              sortable
                              header="Name"
                            ></Column>
                            <Column
                              field="action"
                              sortable
                              body={deleteAssetTemplate}
                              header="Actions"
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                      <ConfirmDeleteDialog
                        visible={isDialogVisible}
                        onHide={() => setDialogVisible(false)}
                        message="Are you sure you want to delete this asset?"
                        onConfirm={handleConfirmDelete}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="profile-4"
                  role="tabpanel"
                  aria-labelledby="profile-tab-4"
                >
                  <div className="row">
                    <div className="col-md-2">
                      <Link>
                        <div
                          className="card text-center"
                          style={{ paddingTop: 25, paddingBottom: 20 }}
                        >
                          <i
                            className="ti ti-file-download"
                            style={{ fontSize: 50 }}
                          ></i>
                          <strong className="mt-2">Tax Certificate</strong>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-2">
                      <Link>
                        <div
                          className="card text-center"
                          style={{ paddingTop: 25, paddingBottom: 20 }}
                        >
                          <i
                            className="ti ti-file-download"
                            style={{ fontSize: 50 }}
                          ></i>
                          <strong className="mt-2">Company Certificate</strong>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-2">
                      <Link>
                        <div
                          className="card text-center"
                          style={{ paddingTop: 25, paddingBottom: 20 }}
                        >
                          <i
                            className="ti ti-file-download"
                            style={{ fontSize: 50 }}
                          ></i>
                          <strong className="mt-2">ID / Passport</strong>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="profile-5"
                  role="tabpanel"
                  aria-labelledby="profile-tab-5"
                >
                  <div className="card">
                    <div className="card-body py-0">
                      <ul
                        className="nav nav-tabs profile-tabs"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="setting-tab-1"
                            data-bs-toggle="tab"
                            href="#setting-1"
                            role="tab"
                            aria-selected="true"
                          >
                            <i className="ti ti-edit me-2"></i>Edits
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="setting-tab-2"
                            data-bs-toggle="tab"
                            href="#setting-2"
                            role="tab"
                          >
                            <i className="ti ti-edit me-2"></i>Release Unit
                          </a>
                        </li>

                      </ul>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="setting-1"
                        role="tabpanel"
                        aria-labelledby="setting-tab-1"
                      >
                        <div className="row">
                          <div className="col-lg-4"></div>
                          <div className="col-lg-4">
                            <div className="card">
                              <div className="card-header">
                                <b>Update Unit</b>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <label>
                                  Unit Name
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <input
                                  className="form-control mt-2"
                                  type="text"
                                  placeholder="Enter unit name"
                                  value={unitName}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setUnitName(value);
                                  }}
                                ></input>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <label>
                                  Unit Type
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className="form-control"
                                  type="text"
                                  value={unitType}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setUnitType(value);
                                  }}
                                >
                                  <option>-- Select --</option>
                                  {unitTypes !== undefined &&
                                    unitTypes.map((item) => {
                                      return (
                                        <option value={item}>{item}</option>
                                      );
                                    })}
                                </select>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <label>
                                  Floor / Unit
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className="form-control mt-2"
                                  value={floorUnit}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setFloorUnit(value);
                                  }}
                                >
                                  <option value="">-- Select --</option>
                                  {currentClickedUnit.NoOfFloors === 0 ? (
                                    <option value="0">Floor / Unit 0</option>
                                  ) : (
                                    <>
                                      <option
                                        value={currentClickedUnit.NoOfFloors}
                                      >
                                        Floor / Unit{" "}
                                        {currentClickedUnit.NoOfFloors}
                                      </option>
                                      {Array.from({
                                        length: currentClickedUnit.NoOfFloors,
                                      }).map((_, index) => (
                                        <option key={index} value={index}>
                                          Floor / Unit {index}
                                        </option>
                                      ))}
                                    </>
                                  )}
                                </select>
                              </div>

                              <div
                                className="card-body"
                                style={{ padding: "10px" }}
                              >
                                <label>
                                  LR Number
                                  <span style={{ color: "red" }}>*</span>:
                                </label>
                                <select
                                  className="form-control mt-2"
                                  type="number"
                                  placeholder="Enter LR Number"
                                  value={lrNumber}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setLrNumber(value);
                                  }}
                                >
                                  <option value="">-- Select --</option>
                                  {facility.landReferenceNumbers !==
                                    undefined &&
                                    facility.landReferenceNumbers.map(
                                      (item) => {
                                        return (
                                          <option value={item}>{item}</option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>

                              <div
                                className="mt-4 mb-2"
                                style={{ marginRight: "5px" }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    HandleUnitUpdate();
                                  }}
                                  style={{ float: "right" }}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="setting-2"
                        role="tabpanel"
                        aria-labelledby="setting-tab-2"
                      >
                        <div className="row">
                          <div className="col-lg-4"></div>
                          <div className="col-lg-4">

                            <div className="card">
                              <div className="card-header">
                                <h5 className="mb-0">Update Unit</h5>
                              </div>
                              <div className="card-body p-3">
                                <h6 className="mb-3"><b>Unit:</b> {unitName}</h6>


                                <div className="d-flex flex-column gap-2">
                                  <p className="mb-1">
                                    <b>Homeowner:</b>
                                    <span className="ms-2">
                                      {unit?.homeOwner ? unit.homeOwner.fullName : "N/A"}
                                    </span>
                                  </p>
                                  <p className="mb-1">
                                    <b>Resident:</b>
                                    <span className="ms-2">
                                      {unit?.resident ? unit.resident.fullName : "N/A"}
                                    </span>
                                  </p>
                                  <p className="mb-1">
                                    <b>Tenant:</b>
                                    <span className="ms-2">
                                      {unit?.tenant ? unit.tenant.fullName : "N/A"}
                                    </span>
                                  </p>
                                </div>



                                {/* Button Group for Actions */}
                                <div className="d-flex flex-wrap gap-2 mt-3">
                                  <button className="btn btn-outline-warning" onClick={() => releaseUnitField("homeOwnerId")}>
                                    Remove Homeowner
                                  </button>
                                  <button className="btn btn-outline-warning" onClick={() => releaseUnitField("residentId")}>
                                    Remove Resident
                                  </button>
                                  <button className="btn btn-outline-warning" onClick={() => releaseUnitField("tenantId")}>
                                    Remove Tenant
                                  </button>
                                </div>

                                {/* Release Entire Unit */}
                                <div className="mt-3">
                                  <button className="btn btn-danger w-100" onClick={releaseEntireUnit}>
                                    Release Entire Unit
                                  </button>
                                </div>
                              </div>
                            </div>


                          </div>
                          <div className="col-lg-4"></div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={`Add a New Asset`}
        visible={newAssetDialog}
        onHide={() => {
          if (!newAssetDialog) return;
          setNewAssetDialog(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div>
              <label>Asset Name:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Enter Asset Name"
                value={assetName}
                onChange={(e) => {
                  const value = e.target.value;
                  setAssetName(value);
                }}
              ></input>
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={HandleAssetSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
};

export default ViewUnit;
